import React, { useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Card, Col, Divider, Row, Space } from "antd"
import { FREE, SUBSCRIPTION } from "../../enums/Plans"
import "./PlanSelector.less"
import { CheckCircleFilled } from "@ant-design/icons"
import { PERCENTAGE, VALUE } from "../../enums/DiscountTypes"
import { devLog } from "../../utils"

const PlanPreview = ({ data, active, onClick }) => {
  const { t } = useTranslation()
  const { plan, title, description, features, priceLabel, finalPrice } = data

  const priceLabelText = useMemo(() => {
    if (!finalPrice) {
      return <h2>{priceLabel}</h2>
    }

    return (
      <>
        <h2 className="stroked" style={{ position: "absolute", bottom: 45 }}>
          {priceLabel}
        </h2>
        <h1>{finalPrice}</h1>
      </>
    )
  }, [priceLabel, finalPrice])

  return (
    <Card
      hoverable
      className={`plan-preview ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <h2>{title}</h2>
      <Divider />
      <Space direction="vertical" size={36} style={{ width: "100%" }}>
        <div className="plan-description">
          <Trans i18nKey={description} components={[<br />]} />
        </div>
        <ul className="plan-features">
          {features.map((feature, index) => (
            <li key={index}>
              <Space>
                <CheckCircleFilled />
                {feature}
              </Space>
            </li>
          ))}
        </ul>
        <Row justify="space-between" align="middle">
          <Col>{priceLabelText}</Col>
          <Col>
            {
              <CheckCircleFilled
                style={{ fontSize: 42, color: active ? "#835EF6" : "#D8D8D8" }}
              />
            }
          </Col>
        </Row>
      </Space>
    </Card>
  )
}

const planColSetting = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
}

const getFinalPriceFromCoupon = coupon => {
  devLog({ coupon })
  if (!coupon) {
    return null
  }

  const planPrice =
    Number(process.env.GATSBY_BRAINTREE_SUBSCRIPTION_PLAN_PRICE) || 199

  devLog({ planPrice })

  if (coupon.criterion) {
    switch (coupon.criterion.discount_type) {
      case VALUE:
        return planPrice - coupon.criterion.value
      case PERCENTAGE:
        return planPrice * (1 - coupon.criterion.percentage)
    }
  }

  return null
}

const PlanSelector = ({ onPlanSelect, value, onChange, coupon }) => {
  const [selectedPlan, setSelectedPlan] = useState(value)
  const { t } = useTranslation()

  const finalPrice = getFinalPriceFromCoupon(coupon)

  const plans = [
    {
      plan: FREE,
      title: t("plan:free.title"),
      description: t("plan:free.description"),
      features: [t("plan:free.feature1"), t("plan:free.feature2")],
      priceLabel: t("plan:free.priceLabel"),
    },
    {
      plan: SUBSCRIPTION,
      title: t("plan:subscription.title"),
      description: t("plan:subscription.description"),
      features: [
        t("plan:subscription.feature1"),
        t("plan:subscription.feature2"),
        t("plan:subscription.feature3"),
        t("plan:subscription.feature4"),
        t("plan:subscription.feature5"),
        t("plan:subscription.feature6"),
        t("plan:subscription.feature7"),
      ],
      priceLabel: t("plan:subscription.priceLabel"),
      finalPrice:
        finalPrice &&
        t("plan:subscription.discountedPriceLabel", { finalPrice }),
    },
  ]

  const handlePlanSelect = plan => {
    onPlanSelect && onPlanSelect(plan)
    onChange && onChange(plan)
    setSelectedPlan(plan)
  }

  return (
    <div className="plan-selector">
      <Row justify="center" gutter={[16, 16]}>
        {plans.map((item, index) => (
          <Col {...planColSetting} key={index}>
            <PlanPreview
              data={item}
              active={selectedPlan === item.plan}
              onClick={() => {
                handlePlanSelect(item.plan)
              }}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default PlanSelector
