import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Button, Col, Form, Input, List, Modal, Row } from "antd"
import {
  devLog,
  formatDate,
  getCurrencySymbol,
  getImageUrl,
  uiHandleError,
  uiHandleSuccess,
} from "../../utils"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import useCommonQueries from "../../hooks/useCommonQueries"
import "./CouponSelector.less"

const GET_AVAILABLE_COUPON_CODES_QUERY = gql`
  query getMyCouponCodes(
    $now: timestamptz
    $itemType: enum_item_types_enum
    $itemId: uuid
    $userId: String
  ) {
    coupon_codes(
      where: {
        user_id: { _eq: $userId }
        criterion: {
          _and: [
            {
              _or: [
                { exclusive_for_content_type: { _eq: $itemType } }
                { exclusive_for_content_type: { _is_null: true } }
              ]
            }
            {
              _or: [
                { exclusive_for_content_id: { _eq: $itemId } }
                { exclusive_for_content_id: { _is_null: true } }
              ]
            }
            {
              _or: [
                { start_at: { _lte: $now } }
                { start_at: { _is_null: true } }
              ]
            }
            { _or: [{ end_at: { _gt: $now } }, { end_at: { _is_null: true } }] }
          ]
        }
        consumed_at: { _is_null: true }
      }
    ) {
      code
      criterion {
        description
        discount_type
        end_at
        exclusive_for_content_id
        exclusive_for_content_type
        id
        percentage
        picture {
          s3_key
        }
        short_description
        start_at
        subtitle
        title
        value
      }
    }
  }
`

const CouponPreview = ({ data, active, onClick }) => {
  const { t } = useTranslation()

  const {
    title,
    subtitle,
    short_description,
    description,
    picture,
    discount_type,
    value,
    percentage,
    start_at,
    end_at,
  } = data.criterion

  const couponImageUrl = getImageUrl(data.criterion.picture)

  let discountValue, discountSuffix
  if (discount_type === PERCENTAGE) {
    discountValue = (percentage * 100).toFixed(0)
    discountSuffix = "%"
  } else {
    discountValue = value
    discountSuffix = getCurrencySymbol(process.env.GATSBY_CURRENCY)
  }

  let validationPeriodHint =
    (start_at || end_at) &&
    `${t("label:valid")}${
      start_at ? t("label:fromDate", { date: formatDate(start_at) }) : ""
    }${end_at ? t("label:toDate", { date: formatDate(end_at) }) : ""}`

  return (
    <div className={`coupon-preview ${active ? "active" : ""}`}>
      <Row gutter={16}>
        <Col span={8}>
          <img className="coupon-image" src={couponImageUrl} alt={title} />
        </Col>
        <Col span={16}>
          <h3 className="coupon-title">{title}</h3>
          <p className="coupon-description">{short_description}</p>
          <Row justify="space-between" align="middle">
            <Col>
              <h3>
                <span>{t("label:discount")} </span>
                <span className="coupon-discount-value">{`${discountValue} ${discountSuffix}`}</span>
              </h3>
            </Col>
            <Col>
              <span>{validationPeriodHint}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {onClick && (
        <div className="coupon-action-wrapper">
          <a
            style={{ width: "100%" }}
            type="text"
            onClick={() => {
              onClick(data)
            }}
          >
            {t("button:useThisCoupon")}
          </a>
        </div>
      )}
    </div>
  )
}

const CouponSelector = ({
  itemType,
  itemId,
  onCouponSelect,
  value,
  onChange,
}) => {
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const { t } = useTranslation()
  const [now, setNow] = useState(new Date())
  const [showCouponModal, setShowCouponModal] = useState(false)
  const [couponCode, setCouponCode] = useState("")
  const { redeemCoupon } = useCommonQueries()

  const { loading, error, data, refetch } = useQuery(
    GET_AVAILABLE_COUPON_CODES_QUERY,
    {
      variables: {
        itemType,
        itemId,
        now,
      },
    }
  )

  useEffect(() => {
    if (data) {
      const _coupon = data.coupon_codes.find(item => item.code === value)
      if (_coupon) {
        setSelectedCoupon(_coupon)
      }
    }
  }, [data, loading, error, value])

  const handleRedeemButtonClick = async () => {
    try {
      devLog({ couponCode })
      await redeemCoupon({ couponCode })
      uiHandleSuccess({
        message: t("message:couponRedeemedSuccess"),
        action: () => {},
      })
      setCouponCode("")
      await refetch()
    } catch (error) {
      uiHandleError({ error })
    }
  }

  const handleCouponSelect = coupon => {
    onCouponSelect && onCouponSelect(coupon)
    onChange && onChange(coupon.code)
    setSelectedCoupon(coupon)
    setShowCouponModal(false)
  }

  return (
    <div className="coupon-selector">
      {selectedCoupon && <CouponPreview data={selectedCoupon} />}
      <Button
        type="primary"
        className="purple"
        onClick={() => {
          setShowCouponModal(true)
        }}
      >
        {t("label:useCoupon")}
      </Button>
      <Modal
        visible={showCouponModal}
        title={t("label:useCoupon")}
        footer={null}
        onCancel={() => {
          setShowCouponModal(false)
        }}
      >
        <div>
          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <Input
                style={{ width: "100%" }}
                value={couponCode}
                placeholder={t("form:label.redeemCouponInputPlaceholder")}
                onChange={evt => {
                  setCouponCode(evt.target.value)
                }}
              />
            </Col>
            <Col>
              <Button onClick={handleRedeemButtonClick}>
                {t("button:redeemCoupon")}
              </Button>
            </Col>
          </Row>
          <br />
          <p className="text-center underline">
            {t("message:useRedeemedCoupon")}
          </p>
          <Form.Item shouldUpdate>
            {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
              const currentCode = getFieldValue("coupon_code")
              return (
                <List
                  className="coupon-list"
                  loading={loading}
                  itemLayout="horizontal"
                  dataSource={data?.coupon_codes}
                  locale={{ emptyText: t("label:noCouponsAvailable") }}
                  renderItem={item => {
                    const active = item.code === currentCode

                    return (
                      <CouponPreview
                        data={item}
                        active={active}
                        onClick={() => {
                          handleCouponSelect(item)
                        }}
                      />
                    )
                  }}
                />
              )
            }}
          </Form.Item>
        </div>
      </Modal>
    </div>
  )
}

export default CouponSelector
