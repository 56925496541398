import React from "react"
import { Checkbox, Col, Form, Input, Row, Select } from "antd"
import provinceListRaw from "../../static/province_data.json"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const provinceList = _.orderBy(provinceListRaw, "DEN_UTS", "asc")

const rowSettings = {
  gutter: 16,
}

const colSettings = {
  xs: 24,
  ms: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
}

const usePaymentFormItems = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row {...rowSettings}>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.firstname")}
            name="firstname"
            rules={[
              {
                required: true,
                message: t("form:error.firstnameRequired"),
              },
            ]}
          >
            <Input placeholder={t("form:label.firstname")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.lastname")}
            name="lastname"
            rules={[
              {
                required: true,
                message: t("form:error.lastnameRequired"),
              },
            ]}
          >
            <Input placeholder={t("form:label.lastname")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.taxCode")}
            name="tax_code"
            rules={[
              {
                required: true,
                message: t("form:error.taxCodeRequired"),
              },
              {
                pattern:
                  /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/i,
                message: t("form:error.taxCodeNotValid"),
              },
            ]}
          >
            <Input placeholder={t("form:label.taxCode")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item label={t("form:label.phoneNumber")} name="phone_number">
            <Input placeholder={t("form:label.phoneNumber")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("form:error.emailRequired"),
              },
              {
                type: "email",
                message: t("form:error.emailNotValid"),
              },
            ]}
          >
            <Input placeholder={t("form:label.email")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}></Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.streetAddress")}
            name={["billing_address", "address_line1"]}
            rules={[
              {
                required: true,
                message: t("form:label.streetAddress"),
              },
            ]}
          >
            <Input placeholder={t("form:label.streetAddress")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.extendedAddress")}
            name={["billing_address", "address_line2"]}
          >
            <Input placeholder={t("form:label.extendedAddress")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Form.Item
            label={t("form:label.locality")}
            name={["billing_address", "city"]}
            rules={[
              {
                required: true,
                message: t("form:error.cityRequired"),
              },
            ]}
          >
            <Input placeholder={t("form:label.locality")} size="large" />
          </Form.Item>
        </Col>
        <Col {...colSettings}>
          <Row {...rowSettings}>
            <Col {...colSettings}>
              <Form.Item
                label={t("form:label.region")}
                name={["billing_address", "region"]}
                rules={[
                  {
                    required: true,
                    message: t("form:error.regionRequired"),
                  },
                ]}
              >
                <Select
                  placeholder={t("form:label.region")}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {provinceList.map(({ DEN_UTS, SIGLA }) => (
                    <Select.Option key={SIGLA} value={SIGLA}>
                      {DEN_UTS}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...colSettings}>
              <Form.Item
                label={t("form:label.postalCode")}
                name={["billing_address", "postal_code"]}
                rules={[
                  {
                    required: true,
                    message: t("form:error.postalCodeRequired"),
                  },
                ]}
              >
                <Input placeholder={t("form:label.postalCode")} size="large" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Form.Item
        name="extra_billing_data"
        valuePropName="checked"
        noStyle
        className="extra-info"
      >
        <Checkbox>{t("form:message.addInvoiceData")}</Checkbox>
      </Form.Item>

      <br />
      <br />

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.extra_billing_data !== currentValues.extra_billing_data
        }
      >
        {({ getFieldValue }) => {
          return getFieldValue("extra_billing_data") ? (
            <Row {...rowSettings}>
              <Col {...colSettings}>
                <Form.Item
                  name="tax_name"
                  label={t("form:label.taxName")}
                  rules={[
                    {
                      required: true,
                      message: t("form:error.taxNameRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("form:label.taxName")} size="large" />
                </Form.Item>
              </Col>
              <Col {...colSettings}>
                {" "}
                <Form.Item
                  label={t("form:label.vatNumber")}
                  name="vat_number"
                  rules={[
                    {
                      required: true,
                      message: t("form:error.vatNumberRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("form:label.vatNumber")} size="large" />
                </Form.Item>
              </Col>
              <Col {...colSettings}>
                <Form.Item
                  label={t("form:label.streetAddress")}
                  name={["business_address", "address_line1"]}
                  rules={[
                    {
                      required: true,
                      message: t("form:error.streetAddressRequired"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("form:label.streetAddress")}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col {...colSettings}>
                <Form.Item
                  label={t("form:label.extendedAddress")}
                  name={["business_address", "address_line2"]}
                >
                  <Input
                    placeholder={t("form:label.extendedAddress")}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col {...colSettings}>
                <Form.Item
                  label={t("form:label.locality")}
                  name={["business_address", "city"]}
                  rules={[
                    {
                      required: true,
                      message: t("form:error.cityRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("form:label.locality")} size="large" />
                </Form.Item>
              </Col>
              <Col {...colSettings}>
                <Form.Item
                  label={t("form:label.postalCode")}
                  name={["business_address", "postal_code"]}
                  rules={[
                    {
                      required: true,
                      message: t("form:error.postalCodeRequired"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("form:label.postalCode")}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : null
        }}
      </Form.Item>
    </>
  )
}

export default usePaymentFormItems
