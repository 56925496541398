import React from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"
import PreferenceTagSelect from "../customFormItems/PreferenceTagSelect"

const SignUpStepSetPreferenceForm = ({ initialValues, onFinish, formRef }) => {
  const { t } = useTranslation()

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Row justify="center">
        <Col>
          <div className="sign-up-step-header">
            <h1>{t("form:signUpStep.setPreference.title")}</h1>
            <p>{t("form:signUpStep.setPreference.description")}</p>
          </div>
        </Col>
      </Row>

      <Form.Item
        name="preferenceTags"
        rules={[
          { required: true, message: t("form:error.preferenceTagsRequired") },
          {
            min: 3,
            type: "array",
            message: t("form:error.selectAtLeastXTags", { count: 3 }),
          },
          // {
          //   max: 7,
          //   type: "array",
          //   message: t("form:error.selectAtMaxXTags", { count: 7 }),
          // },
        ]}
      >
        <PreferenceTagSelect />
      </Form.Item>
    </Form>
  )
}

export default SignUpStepSetPreferenceForm
