import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"

import logo from "../../../static/assets/images/logo-tm.svg"
import { INSUFFICIENT_FUNDS } from "../../../functions/lib/error-codes"
import { devLog } from "../../utils"
import ErrorScreen from "../ErrorScreen"

const SignUpStepCompleteForm = ({ initialValues, onFinish, formRef }) => {
  const { t } = useTranslation()

  const [error, setError] = useState()

  useEffect(() => {
    const { error } = initialValues
    if (!error) {
      return
    }

    devLog({ error })

    const errorObject = {
      code: error?.code,
      message: btoa(error?.message),
    }

    if (error?.message === "Insufficient Funds") {
      errorObject.code = INSUFFICIENT_FUNDS
    }

    setError(errorObject)
  }, [initialValues])

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Row justify="center">
        {error ? (
          <Col>
            <ErrorScreen error={new Error(atob(error.message))} noAction />
          </Col>
        ) : (
          <Col>
            <Row justify="center">
              <Col>
                <div
                  style={{
                    width: 200,
                    height: 200,
                    padding: 35,
                    borderRadius: "50%",
                    marginBottom: 30,
                    backgroundColor: "black",
                  }}
                >
                  <img style={{ height: "100%" }} src={logo} alt="logo" />
                </div>
              </Col>
            </Row>
            <div className="sign-up-step-header">
              <h1>{t("form:signUpStep.complete.title")}</h1>
              <p>{t("form:signUpStep.complete.description")}</p>
            </div>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default SignUpStepCompleteForm
